import { openedBook } from "../../assets";
import "./index.css";

function NewsHeader() {
  return (
    <>
      <div className="news-header-container max-h-72 flex flex-row text-left justify-evenly mb-16 p-5 md:pl-20 md:pr-20">
        <div className="text-white" style={{ maxWidth: "680px" }}>
          <h1 className="text-5xl mt-10 mb-10 ">News & Activities</h1>
          <p
            className="text-justify"
            style={{ maxWidth: "680px", letterSpacing: "0.16px" }}>
            Stay updated with the latest happenings at Origin Institute of
            Technology! Explore our recent events, student achievements, and
            industry insights. Join us in celebrating innovation and growth as
            we empower future tech leaders. Discover how we’re shaping the IT
            landscape and fostering a vibrant learning community.
          </p>
        </div>
        <div>
          <img
            src={openedBook}
            alt="Opened Book"
            className="w-[402px] h-[402px] whitespace-nowrap overflow-visible hidden md:block rotate-6"
          />
        </div>
      </div>
    </>
  );
}

export default NewsHeader;
