import "./App.css";
import "./index.css";
import Heading from "./layout/Heading";
import Footer from "./layout/Footer";
import { RouterConfig } from "./components";
import { QandAProvider } from "./stateManagement/AppProvider";
function App() {
  return (
    <QandAProvider>
      <div
        className="flex flex-col  w-[100vw]"
        style={{ scrollbarWidth: "auto" }}>
        <Heading />
        <div>
          <RouterConfig />
        </div>
        <Footer />
      </div>
    </QandAProvider>
  );
}

export default App;
