import { aboutHeading, aboutHeadingLogo } from "../../assets";
import "./index.css";

export default function AboutHeading() {
  return (
    <>
      <div className="flex flex-col md:flex-row justify-center items-center gap-12 dark-background pb-4 about-container">
        <div className="flex flex-col md:pb-8  justify-start items-center w-max-[678px]">
          <div>
            <img
              src={aboutHeadingLogo}
              style={{ width: "260px", marginTop: "20px" }}
              alt="Institude Logo"
            />
          </div>
          <h1 className="heading-title md:mb-4">WELCOME TO OIT</h1>
          <p className="heading-info text-justify p-5 w-max-[678px]">
            Origin Institute of Technology (OIT), we shape the future of
            technology professionals by offering innovative, hands-on education
            that bridges the gap between theory and practical application.
            Whether you are taking your first step into the world of IT or
            enhancing your existing skills, OIT provides an unparalleled
            learning environment that ensures you achieve your career
            aspirations. <br />
            <br />
            Our programs are designed to keep pace with the rapidly evolving
            tech landscape, offering cutting-edge courses that equip students
            with the skills in demand by today’s top employers. At OIT, your
            success is our priority, and we’re committed to fostering an
            educational experience that prepares you for the challenges and
            opportunities of tomorrow.
          </p>
        </div>

        <img
          src={aboutHeading}
          alt="Discussion"
          style={{
            width: "423px",
            height: "400px",
            // boxShadow: "0px 4px 4px 10px #00A6FE",
          }}
          className="md:mt-32 translate-y-4"
        />
      </div>
    </>
  );
}
