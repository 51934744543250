import ComingSoon from "./ComingSoon";

function ITpjMnm() {
  return (
    <>
      <ComingSoon />
    </>
  );
}

export default ITpjMnm;
