function TeachingApproachCard({ icon, title, info }) {
  return (
    <>
      <div className="w-full items-center justify-center flex flex-col">
        <div
          className="bg-white p-3 rounded-full overflow-y-visible translate-y-9 w-fit "
          style={{
            boxShadow: "0px 4px 4px 0px rgba(0, 166, 254, 0.25)",
          }}>
          <img
            src={icon}
            alt={title}
            className="overflow-y-visible"
            style={{
              width: "40px",
              height: "40px",
              justifySelf: "center",
              position: "relative",
            }}
          />
        </div>
        <div
          className="flex flex-col items-start justify-center overflow-y-visible text-justify p-5 pt-14"
          style={{
            backgroundColor: "var(--Backgrounds-Primary, #FFF)",
            boxShadow: "0px 4px 4px 4px #00A6FE",
            width: "300px",
            height: "290px",
          }}>
          <h1
            className="text-sm md:text-lg font-bold mb-2 -translate-y-10"
            style={{ color: "#162B3E" }}>
            {title}
          </h1>
          <p
            className="text-sm md:text-lg text-start font-normal -translate-y-8"
            style={{ color: "#162B3E" }}>
            {info}
          </p>
        </div>
      </div>
    </>
  );
}

export default TeachingApproachCard;
