import React, { useState } from "react";
import {
  homeHeaderImg,
  heroHeaderImg2,
  leftArrow,
  rightArrow,
  heroHeaderImg3,
} from "../../assets";
import "./index.css";

function HeroHeader() {
  const [bg, setBg] = useState(0); // current index of background
  const backgrounds = [homeHeaderImg, heroHeaderImg2, heroHeaderImg3]; // Add more images as needed

  const handleNext = () => {
    setBg((prevBg) => (prevBg + 1) % backgrounds.length);
  };

  const handlePrev = () => {
    setBg((prevBg) => (prevBg - 1 + backgrounds.length) % backgrounds.length);
  };

  const handlePaginationClick = (index) => {
    setBg(index);
  };

  return (
    <>
      <div
        style={{
          maxHeight: "404px",
          backgroundImage: `url(${backgrounds[bg]})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}>
        <div className="justify-between items-baseline flex-row text-center pt-12 flex">
          <button className="text- px-4" onClick={handlePrev}>
            <img src={leftArrow} alt="prev" className="bg-gray-500" />
          </button>

          <div
            className="w-fit pt-10 p-10 h-40"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.38)",
            }}>
            <h1 className="sm:text-4xl md:text-5xl font-semibold">
              More than just a degree, Join with OIT
            </h1>
          </div>

          <button className="text-3xl px-4" onClick={handleNext}>
            <img src={rightArrow} alt="prev" className="bg-gray-500" />
          </button>
        </div>
        <div
          className="flex justify-center mt-40"
          style={{
            bottom: "0",
            position: "relative",
          }}>
          {backgrounds.map((_, index) => (
            <span
              key={index}
              onClick={() => handlePaginationClick(index)}
              style={{
                display: "inline-block",
              }}
              className={`mx-1 cursor-pointer w-4 h-4 rounded-full ${
                bg === index ? "bg-gray-900" : "bg-white"
              }`}></span>
          ))}
        </div>
      </div>
    </>
  );
}

export default HeroHeader;
