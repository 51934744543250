import ComingSoon from "./ComingSoon";

function UiUx() {
  return (
    <>
      <ComingSoon />
    </>
  );
}

export default UiUx;
