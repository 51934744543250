import ComingSoon from "./ComingSoon";

function WebDevBoot() {
  return (
    <>
      <ComingSoon />
    </>
  );
}

export default WebDevBoot;
