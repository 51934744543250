import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Link, NavLink } from "react-router-dom";
import { grayLogo, siteLogo } from "../assets";
import { FaChevronDown } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";

// Navigation items for the header
const navItems = [
  { label: "E-Library", to: "/coming-soon" },
  { label: "Alumni", to: "/coming-soon" },
  { label: "Forum", to: "/coming-soon" },
  { label: "FAQs", to: "/faqs" },
];

// Dropdown menu items
const dropdownItems = {
  programme: [
    { label: "Diploma", to: "/diploma" },
    { label: "Professional Development", to: "./professional-development" },
    { label: "Others", to: "/coming-soon" },
  ],
  partnership: [
    { label: "Academic Partnership", to: "/coming-soon" },
    { label: "Corporate Partnership", to: "/coming-soon" },
  ],
};

// Desktop Navbar Component
const DesktopNav = ({ toggleMobileMenu }) => (
  <nav className="flex flex-col justify-between  bg-white fixed text-lg w-full z-30">
    <div className="flex items-center justify-between">
      <div className="flex justify-start items-center">
        <NavLink to="/" className="flex items-center">
          <img
            src={siteLogo}
            alt="Logo"
            className="h-32 w-auto ml-5 md:ml-16"
          />
          <h1 className="text-2xl hidden md:block pl-5 heading-h1">
            ORIGIN INSTITUTE OF TECHNOLOGY
          </h1>
        </NavLink>
      </div>
      <div
        className="flex items-start md:items-center gap-6 mr-12"
        style={{ zIndex: "999" }}>
        <button className="border-2 px-10 py-2 rounded-full header-button login-button hover:bg-gradient-to-r hover:from-sky-500 hover:to-blue-950 hover:text-white">
          Login
        </button>
        {navItems.map((item, index) => (
          <NavLink
            key={index}
            to={item.to}
            className="hover-cyan whitespace-nowrap hidden md:block"
            onClick={toggleMobileMenu}>
            {item.label}
          </NavLink>
        ))}
        <button className="border-2 px-10 py-2 rounded-full login-button hover:bg-gradient-to-r hover:from-sky-500 hover:to-blue-950 hover:text-white">
          Login
        </button>
      </div>
    </div>
    <SubNav />
  </nav>
);

// Sub Navigation Component for desktop
const SubNav = () => (
  <div
    className="flex flex-col md:flex-row items-center gap-7 text-white w-full justify-center"
    style={{ backgroundColor: "#162B3E" }}>
    <Link to="/" className="page-option hover-cyan">
      Home
    </Link>
    <Link to="/about" className="page-option hover-cyan">
      About Us
    </Link>
    <DropdownMenu title="Programme" items={dropdownItems.programme} />
    <DropdownMenu title="Partnership" items={dropdownItems.partnership} />
    <Link to="/news-and-articles" className="page-option hover-cyan">
      News & Activities
    </Link>
  </div>
);

// Dropdown Menu Component
const DropdownMenu = ({ title, items, toggleMobileMenu }) => (
  <div className="relative h-auto page-option-with-dropdown ">
    <div className="flex items-center gap-1">
      {title} <FaChevronDown className="w-3 ml-2" />
    </div>

    <div className="header-button md:absolute ">
      {items.map((item, index) => (
        <Link
          key={index}
          to={item.to}
          className="dropdown-item"
          onClick={toggleMobileMenu}>
          {item.label}
        </Link>
      ))}
    </div>
  </div>
);

// Mobile Navbar Component
const MobileNav = ({ isMobileMenuOpen, toggleMobileMenu }) => (
  <div className="flex flex-col w-2/3">
    <div className="flex justify-between items-center flex-row w-full">
      <div className="flex flex-row items-center justify-start">
        {" "}
        <FiMenu size={30} color="black" onClick={toggleMobileMenu} />
        <img src={siteLogo} alt="Logo" className="h-24 w-auto" />
      </div>
      <button className="border-2 px-10 py-2 max-h-10 rounded-full  login-button hover:bg-gradient-to-r hover:from-sky-500 hover:to-blue-950 hover:text-white">
        Login
      </button>
    </div>
    {isMobileMenuOpen && (
      <div
        className="md:hidden flex flex-col items-start text-white gap-7 p-5  fixed min-h-full overflow-visible w-3/4"
        style={{ backgroundColor: "#162B3E", zIndex: "99" }}>
        <img src={grayLogo} alt="Logo" className="h-36 w-auto ml-5 md:ml-16" />
        <Link
          to="/"
          className="page-option hover-cyan"
          onClick={toggleMobileMenu}>
          Home
        </Link>
        <Link
          to="/about"
          className="page-option hover-cyan"
          onClick={toggleMobileMenu}>
          About Us
        </Link>
        <DropdownMenu title="Programme" items={dropdownItems.programme} />
        <DropdownMenu title="Partnership" items={dropdownItems.partnership} />
        <Link
          to="/news-and-articles"
          className="page-option hover-cyan"
          onClick={toggleMobileMenu}>
          News & Activities
        </Link>
        {navItems.map((item, index) => (
          <NavLink
            key={index}
            to={item.to}
            className="nav-bar-list"
            onClick={toggleMobileMenu}>
            {item.label}
          </NavLink>
        ))}
      </div>
    )}
  </div>
);

const Heading = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  // Function to handle the click outside
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setMobileMenuOpen(false);
    }
  };

  // Add event listener for detecting clicks outside
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <div className="hidden md:block w-full fixed" style={{ zIndex: "999" }}>
        <DesktopNav toggleMobileMenu={toggleMobileMenu} />
      </div>
      <div
        className="md:hidden w-full"
        ref={dropdownRef}
        style={{ zIndex: "999" }}>
        <MobileNav
          isMobileMenuOpen={isMobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
        />
      </div>
    </>
  );
};

export default Heading;
