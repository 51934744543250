import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

function ComingSoon() {
  const text = "Coming Soon........".split(""); // Split the text into an array of characters
  return (
    <>
      <Helmet>
        <title>Coming Soon | Origin Institude of Technology</title>
      </Helmet>
      <div className="flex-grow h-full">
        <h1 className="dark-text pt-96 font-extrabold light-blue-shadow pb-96 text-white text-9xl">
          {text.map((letter, i) => (
            <motion.span
              initial={{ opacity: 0, scale: 0.1 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.35,
                delay: i / 5,
                repeat: Infinity,
                repeatType: "loop",
                repeatDelay: 2,
              }}
              key={i}>
              {letter === " " ? "\u00A0" : letter} {/* Preserve spaces */}
            </motion.span>
          ))}
        </h1>
      </div>
    </>
  );
}

export default ComingSoon;
