import ComingSoon from "./ComingSoon";

function MobileDev() {
  return (
    <>
      <ComingSoon />
    </>
  );
}

export default MobileDev;
